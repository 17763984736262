import React from 'react';
import styled, { withTheme } from 'styled-components';
import Link from 'next/link';

import { Section } from '@components/Layout';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { H2, H3, Body1, Body2, Eyebrow, Bold } from '@components/Text';
import Anchor from '@components/Primitives/Anchor';
import AppStore from '@components/Images/AppStore';
import GooglePlay from '@components/Images/GooglePlay';
import { PATHS } from '@constants/paths';
import { useWindowSize } from '../../hooks/useWindowSize';
import CheckCircle from '../Icons/ui/CheckCircle';
import { parseQueryString } from '@utils/usefulFuncs';
import SignUpForm from '../Form/SignUpForm';
import { SignupRewardDisclaimer } from '@components/Disclaimers/SignupRewardDisclaimer';
import { Image } from '@components/Images';

import learnAndEarnPhone1 from '@public/images/learnAndEarnPhone1.png';

const MobileHeadingWrapper = styled.div`
  video {
    min-height: 100%;
    min-width: 100%;
    height: auto !important;
    width: auto !important;
    max-width: 100%;
  }

  @media (min-width: 500px) {
    h2 {
      font-size: 1.5em;
      line-height: 120%;
    }

    p {
      font-size: 1.3em;
      line-height: 120%;
    }
  }

  @media (max-width: 625px) {
    .heading-wrapper {
      max-width: 500px;
    }
  }

  @media (max-width: 425px) {
    .heading-wrapper {
      max-width: 360px;

      p {
        line-height: 140%;
      }
    }
  }

  @media (max-width: 360px) {
    h2 {
      font-size: 0.8em;
      line-height: 120%;
    }

    p {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const LearnAndEarnOverviewMobile = () => {
  return (
    <Section>
      <Column padding="50px 20px 70px" maxWidth="100%">
        <MobileHeadingWrapper>
          <div className="heading-wrapper">
            <Column maxWidth="600px" margin="0 auto">
              <H3>
                <Bold>Earn up to $50 to invest</Bold> when you level up your skills
              </H3>

              <Place padding="8px 0 0 0" width="90%" zIndex="1">
                <Body2>
                  Unlock new <b>Learn and Earn</b> lessons at every milestone and Tornado will continue to reward your
                  progress.
                </Body2>
              </Place>
            </Column>
          </div>

          <div
            style={{
              position: 'relative',
              maxWidth: '660px',
              margin: '0 auto',
              padding: '40px 0 0',
            }}
          >
            <Image src={learnAndEarnPhone1} alt="" useNextImage={true} />
          </div>
        </MobileHeadingWrapper>
      </Column>
    </Section>
  );
};

const LearnAndEarnOverviewFull = ({ windowWidth, windowHeight }) => {
  const headerHeight = 120;
  const usableHeight = windowHeight - headerHeight;

  const imageAspectRatio = 1;
  const imageWidth = Math.min(usableHeight * imageAspectRatio, 640);

  const leftOffset = Math.max(740 - imageWidth, 0);

  return (
    <Section>
      <Place position="relative" style={{ margin: '0 0 90px', padding: '0 0 70px' }}>
        <Column className="section-container" padding="70px 0 10px">
          <Place
            position="relative"
            zIndex="2"
            width={windowWidth < 1100 ? `${imageWidth - 100}px` : `${imageWidth}px`}
            height="auto"
          >
            <Image src={learnAndEarnPhone1} alt="" useNextImage={true} />
          </Place>
        </Column>

        <Place
          position="absolute"
          top={windowWidth < 1100 ? '25px' : '100px'}
          left={windowWidth < 1100 ? `${490 - 100 + leftOffset / 2}px` : `${490 + leftOffset / 2}px`}
          zIndex="1"
          style={{ maxWidth: '630px', margin: '70px 100px 10px' }}
        >
          <H3>
            <Bold>Earn up to $50 to invest</Bold> when you level up your skills
          </H3>

          <Place padding="50px 0 50px" width="500px">
            <Body1>
              Unlock new <b>Learn and Earn</b> lessons at every milestone and Tornado will continue to reward your
              progress.
            </Body1>
          </Place>
          {/* <Place padding="0 0 20px 0" width="400px">
            <SignUpForm isEmbedded={true} />
            <Row padding="20px 0 0 0">
              <Place padding="1px 8px 0px">
                <CheckCircle width={17} />
              </Place>
              <Eyebrow>Up to $1,000 cash to invest when you join</Eyebrow>
            </Row>
            <Row padding="12px 0 0 8px">
              <SignupRewardDisclaimer />
            </Row>
          </Place> */}
        </Place>
      </Place>
    </Section>
  );
};

const LearnAndEarnOverview = ({ theme }) => {
  const [showAppStoreLinks, setShowAppStoreLinks] = React.useState(false);

  React.useEffect(() => {
    const query = parseQueryString(window.location.search);
    if (query.appStoreCfg != 2) {
      setShowAppStoreLinks(true);
    }
  }, []);

  const { isMobile } = theme;

  const { width, height } = useWindowSize();

  return (
    <Section>
      {isMobile ? (
        <LearnAndEarnOverviewMobile
          theme={theme}
          windowWidth={width}
          windowHeight={height}
          showAppStoreLinks={showAppStoreLinks}
        />
      ) : (
        <LearnAndEarnOverviewFull theme={theme} windowWidth={width} windowHeight={height} />
      )}
    </Section>
  );
};

export default withTheme(LearnAndEarnOverview);
