import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  img {
    opacity: ${(props) => (isNaN(props.opacity) ? 1 : props.opacity)};
  }
`;

const CNBC = ({ width, opacity }) => {
  const aspectRatio = 556 / 200;
  const height = width / aspectRatio;
  return (
    <Wrapper opacity={opacity}>
      <img src="/images/cnbc.png" width={width} height={height} alt="CNBC logo" />
    </Wrapper>
  );
};

export default CNBC;
