import React from 'react';
import styled, { withTheme } from 'styled-components';
import Link from 'next/link';
import { Button3 } from '@components/Buttons';
import { Column, Place, Row } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { H3, Bold } from '@components/Text';
import { Gradient1Background } from '@components/Backgrounds';
import { TrackingEvents } from 'tracking';
import { SignupRewardDisclaimer } from '@components/Disclaimers/SignupRewardDisclaimer';

const DesktopPromoWrapper = styled(Row)`
  position: relative;
  padding: 60px;
  overflow: hidden;

  height: 688px;
`;

const BorderContainer = styled(Column)`
  position: relative;
  z-index: 1;
  padding: 80px;
  border: 2px solid ${(props) => props.theme.charcoal};
  border-radius: 16px;
  min-height: 524px;
  justify-content: space-between;
`;

const MobileBorderContainer = styled(Column)`
  position: relative;
  z-index: 1;
  padding: 7.5%;
  border: 2px solid ${(props) => props.theme.charcoal};
  border-radius: 16px;
  height: 425px;

  @media (max-width: 700px) {
    padding: 24px;
  }
`;

const DesktopWrapper = styled.div`
  @media (max-width: 1125px) {
    h3 {
      font-size: 0.8em;
      line-height: 120%;
    }
  }
`;

const DisclaimerWrapper = styled.div`
  padding-top: 24px;
`;

const MobilePromoWrapper = styled(Column)`
  position: relative;
  padding: 40px;
  overflow: hidden;

  @media (max-width: 525px) {
    padding: 24px;
  }
`;

const CashPromoMobile = ({ theme }) => {
  const handleGetStartedClick = () => {
    TrackingEvents.splash.clickGetStartedButton.send({
      Context: 'Cash Promo Section',
    });
  };
  return (
    <Section background={theme.secondary.white}>
      <MobilePromoWrapper position="relative" padding="40px" style={{ overflow: 'hidden' }}>
        <Place position="absolute" top="0" left="0" height="688px" width="1440px">
          <Gradient1Background />
        </Place>
        <MobileBorderContainer height="488px" vAlign="center">
          <div>
            <H3>
              <Bold>Get up to $1,000&nbsp;cash</Bold>
              <br />
              to invest when&nbsp;you join&nbsp;tornado
            </H3>
            <Place padding="40px 0 0 0">
              <Link href="/app/signup" passHref>
                <div>
                  <Button3 width="216px" onClick={handleGetStartedClick}>
                    get started
                  </Button3>
                </div>
              </Link>
            </Place>
          </div>
          <DisclaimerWrapper>
            <SignupRewardDisclaimer />
          </DisclaimerWrapper>
        </MobileBorderContainer>
      </MobilePromoWrapper>
    </Section>
  );
};

const CashPromoFull = ({ theme }) => {
  const handleGetStartedClick = () => {
    TrackingEvents.splash.clickGetStartedButton.send({
      Context: 'Cash Promo Section',
    });
  };
  return (
    <DesktopWrapper>
      <Section background={theme.secondary.white}>
        <Column>
          <DesktopPromoWrapper valign="center">
            <Place position="absolute" top="0" left="0" height="688px" width="1440px">
              <Gradient1Background />
            </Place>
            <BorderContainer>
              <div style={{ maxWidth: '800px' }}>
                <H3>
                  <Bold>Get up to $1,000 cash </Bold>{' '}
                </H3>
                <H3>to invest when you join tornado</H3>
              </div>
              <div>
                <Place padding="65px 0 0 0">
                  <Link href="/app/signup" passHref>
                    <div>
                      <Button3 width="216px" onClick={handleGetStartedClick}>
                        get started
                      </Button3>
                    </div>
                  </Link>
                </Place>
                <DisclaimerWrapper>
                  <SignupRewardDisclaimer />
                </DisclaimerWrapper>
              </div>
            </BorderContainer>
          </DesktopPromoWrapper>
        </Column>
      </Section>
    </DesktopWrapper>
  );
};

const CashPromoMain = ({ theme }) => {
  const { isMobile } = theme;
  return isMobile ? <CashPromoMobile theme={theme} /> : <CashPromoFull theme={theme} />;
};

export default withTheme(CashPromoMain);
