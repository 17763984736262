import React from 'react';
import Link from 'next/link';

import { Disclaimer } from '@components/Text/Disclaimer';
import { PATHS } from '@constants/paths';
import Anchor from '@components/Primitives/Anchor';

export const SignupRewardDisclaimer = () => {
  return (
    <Disclaimer>
      New brokerage accounts only, for a limited time. Valid bank account link and/or qualifying deposit may be
      required. Other terms and conditions apply. See the{' '}
      <Link href={PATHS.REFERRAL_PROGRAM_RULES} passHref>
        <Anchor openInNewTab>Official Rules</Anchor>
      </Link>{' '}
      for details.
    </Disclaimer>
  );
};
