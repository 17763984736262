import React from 'react';
import { withTheme } from 'styled-components';
import { Image } from '@components/Images';
import Link from 'next/link';
import { Row, Column, Place } from '@lib/nvstr-common-ui.es';
import { Section } from '@components/Layout';
import { BulletList } from '@components/Blocks';
import { H2, Body1, Bold } from '@components/Text';
import { Button3 } from '@components/Buttons';
import { PATHS } from '@constants/paths';
import { TrackingEvents } from '../../tracking';
import HeadingImageBulletsSideBySide from '@components/Blocks/HeadingImageBulletsSideBySide';

import phone3_2x from '@public/images/phone3@2x.png';

const BulletItems = () => {
  return (
    <BulletList
      listItems={[
        <Body1 key="convo-item1">Explore and filter stocks by your interests and goals</Body1>,
        <Body1 key="convo-item2">Choose your own stocks, or opt for a diversified set of ETFs for more guidance</Body1>,
        <Body1 key="convo-item3">Optimize your allocations in one click so your portfolio is always diversified</Body1>,
      ]}
    />
  );
};

const OptimizePortfolioFeatureMobile = () => {
  const handleLearnMoreClick = () => {
    TrackingEvents.splash.clickLearnMoreButton.send();
  };
  return (
    <Section>
      <Column className="section-container" padding="150px 0 0 0" hAlign="center">
        <Place width="100%" maxWidth="600px">
          <H2>
            <Bold>
              Optimize your
              <br />
              portfolio
            </Bold>{' '}
            in an
            <br />
            instant
          </H2>
          <Row padding="40px 0 0 0">
            <Body1>
              Whatever level of experience you have, easily build
              <br /> your portfolio and optimize in one click.
            </Body1>
          </Row>

          <Row padding="50px 0 50px" hAlign="center">
            <Image src={phone3_2x} alt="" />
          </Row>

          <BulletItems />
          <Row padding="50px 0 50px" hAlign="center">
            <Link href={PATHS.ABOUT} passHref>
              <a className="no-text-decoration">
                <Button3 width="320px" onClick={handleLearnMoreClick}>
                  learn more about tornado
                </Button3>
              </a>
            </Link>
          </Row>
        </Place>
      </Column>
    </Section>
  );
};

const OptimizePortfolioFeatureFull = () => {
  const handleLearnMoreClick = () => {
    TrackingEvents.splash.clickLearnMoreButton.send();
  };

  return (
    <Section>
      <Column className="section-container" width="100%" hAlign="center" padding="0 0 144px 0">
        <div style={{ maxWidth: '1100px' }}>
          <HeadingImageBulletsSideBySide
            headingComponent={
              <H2>
                <Bold>
                  Optimize your
                  <br />
                  portfolio
                </Bold>{' '}
                in
                <br />
                an instant
              </H2>
            }
            subHeadingComponent={
              <Body1>
                Whatever level of experience you have, easily build
                <br /> your portfolio and optimize in one click.
              </Body1>
            }
            bulletsComponent={<BulletItems />}
            imageData={{
              src: phone3_2x,
            }}
            cta={
              <Link href={PATHS.ABOUT} passHref>
                <a className="no-text-decoration">
                  <Button3 width="320px" onClick={handleLearnMoreClick}>
                    learn more about tornado
                  </Button3>
                </a>
              </Link>
            }
            isImageLeft={false}
          />
        </div>
      </Column>
    </Section>
  );
};

const OptimizePortfolioFeatureMain = ({ theme }) => {
  const { isMobile } = theme;
  return isMobile ? <OptimizePortfolioFeatureMobile /> : <OptimizePortfolioFeatureFull />;
};

export default withTheme(OptimizePortfolioFeatureMain);
